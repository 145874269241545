import React from 'react';

import Layout from '../../components/Layout';
// import IconTextGrid, {
//   startingMyfembreeIconGrid
// } from '../../components/IconTextGrid/IconTextGrid';
// import InTheGroove, {
//   inTheGrooveContent
// } from '../../components/InTheGroove/InTheGroove';
// import ForgetToTake from '../../components/ForgetToTake/ForgetToTake';
import FloatingLinks, {
  StartingMyfembreeFloatingLinkData
} from '../../components/FloatingLinks/FloatingLinks';
// import KnockoutDriver from '../../components/KnockoutDriver/KnockoutDriver';

// import Shield from '../../assets/svgs/Shield.svg';
// import HowToStart from '../../components/HowToStart/HowToStart';
// import Hero3, { readyToStart } from '../../components/Hero/Hero3';
// import QuickLinks2, {
//   QuickLinks2ReadyToStart
// } from '../../components/QuickLinks2/QuickLinks2';
import GlobalStartingMyFembree from '../../components/GlobalStartingMyFembree/global-starting-myfembree';

const Home = () => {
  const {
    refs: [headerRef, howToStartRef, forgetRef, inTheGrooveRef],
    floatingLinksProps
  } = StartingMyfembreeFloatingLinkData();
  return (
    <Layout section="UF" page="/uterine-fibroids/starting-myfembree/">
      <>
        <GlobalStartingMyFembree />
        {/* <Hero3 {...{ ...readyToStart, headerRef }}>
          <QuickLinks2 {...QuickLinks2ReadyToStart} />
        </Hero3>
        <FloatingLinks {...floatingLinksProps} />
        <HowToStart {...{ floatingRef: howToStartRef }} />
        <IconTextGrid {...startingMyfembreeIconGrid} />
        <ForgetToTake {...{ floatingRef: forgetRef }} />
        <InTheGroove
          {...{
            ...inTheGrooveContent,
            bladeProps: {
              ...inTheGrooveContent.bladeProps,
              floatingRef: inTheGrooveRef
            }
          }}
        /> */}
        {/* <KnockoutDriver
          {...{
            icon: <Shield />,
            iconStroke: 'white',
            iconBgColor: 'dark-green',
            prompt:
              'If you have commercial insurance, you could pay as little as $5 a month* for your Myfembree prescription (up to $5000 a year).',
            buttonProps: {
              label: 'LEARN MORE',
              ariaLabel: '',
              color: 'secondary-fuchsia',
              target: '_self',
              href: '/uterine-fibroids/cost-and-support/',
              position: 'right'
            },
            buttonAltProps: {
              label: 'TERMS & CONDITIONS',
              ariaLabel: '',
              color: 'primary-purple-alt',
              target: '_self',
              href: '/uterine-fibroids/cost-and-support/#terms-and-conditions',
              position: 'right'
            },
            color: 'purple-100',
            disclaimerText:
              '*$5 per monthly Myfembree prescription or $15 for a 90-day prescription, subject to a maximum of $5000 per calendar year.<br class="hidden md:block" /> Additional Terms and Conditions apply. Click above to learn more.'
          }}
        /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/uterine-fibroids/starting-myfembree/';
  const title =
    'Starting MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) for Uterine Fibroid Relief | Safety Info';
  const pageTitle =
    'Starting MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) for Uterine Fibroid Relief | Safety Info';
  const description =
    'Learn how to get started on MYFEMBREE® to reduce heavy menstrual bleeding from uterine fibroids in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
